;(function(){
    window.GSApp = window.GSApp || {};

    GSApp.messages = {
        "server_error": "Server error. Please try again later."
    };

    GSApp.getStandardMessage = function(key) {
        return GSApp.messages[key];
    }
})();
